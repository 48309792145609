<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>
    <v-card>
      <v-card-title class="display-2 mb-4">{{$t('profile.activities.edit.title')}}</v-card-title>
      <v-card-text>
        <p>{{$t('profile.activities.edit.description')}}</p>

        <v-alert type="info">Please note that making any change to this activity will remove the synced icon (<v-icon small color="white" class="" :title="$t('results.verified-result')">fal fa-location</v-icon>) from any results based on this activity. Some events may not allow modified activities at all.</v-alert>
      </v-card-text>

      <v-form ref="form" v-model="valid" lazy-validation v-if="activity">
        <v-card-text>      
          <v-select 
            :label="$t('profile.activities.edit.activity-type')"
            v-model="activity.type"
            :items="siteData.activities"
            prepend-icon="fa-"
            item-value="type"
            :item-text="x => $t('profile.activities.types.'+x.type)"
            />

          <DateAndTimeWithTimeZonePicker v-model="activity.start" ref="timePicker" :timeZone="timeZoneOlson" :label="$t('profile.activities.entry.activity-date-start')" :timeLabel="$t('profile.activities.entry.activity-time-start')" />

          <DistanceTextArea
            v-model="activity.dist" 
            icon="fa-ruler"
            :unit="unitType"
            :label="$t('profile.activities.entry.distance-label', { unit: unitTypeDisplay, requirement: activityService.requireDistance(activity) ? $t('shared.required') : $t('shared.optional') } )" 
            :rules="numberRules"
            />

          <DurationTextArea
            v-model="activity.time_s" 
            icon="fa-clock"
            :label="$t('profile.activities.entry.duration')" 
            >
          </DurationTextArea>

          <DistanceTextArea
            v-if="showSteps"
            v-model="activity.steps" 
            icon="fa-shoe-prints"
            :mode="$helpers.UnitType.NUMBER"
            :label="$t('profile.activities.steps')" 
            >
          </DistanceTextArea>

          <DistanceTextArea
            v-if="showCustomField"
            v-model="activity.custom" 
            icon="fa-tally"
            :mode="$helpers.UnitType.NUMBER"
            :unit="profile.unit"
            :label="$t('profile.activities.entry.custom')" 
            />            
        </v-card-text>      
        
        <v-card-text>   
          <v-alert v-if="infoMessage" class="mt-4" type="info">{{infoMessage }}</v-alert>
          <v-alert v-if="error || !valid" class="mt-4" type="error">{{error || $t('shared.validation-error-message') }}</v-alert>
          <div v-if="validationError" class="mt-8 mb-8 d-flex">
            <v-btn :disabled="!valid" color="primary" outlined :loading="$store.getters.isLoading" @click="submit(true)">{{$t('profile.activities.entry.save-anyway')}}</v-btn>
            <v-spacer/>
            <v-btn :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit(false)">{{$t('profile.activities.entry.validate-again')}}</v-btn>
          </div>
          <v-btn v-else class="mt-8 mb-8" block :disabled="!valid" color="primary" :loading="$store.getters.isLoading" @click="submit(false)">{{$t('shared.save')}}</v-btn>
        </v-card-text>   
      </v-form>
    </v-card>
  </div>
</template>



<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import { DateTime } from 'luxon'
import siteData from '@/data/site.json'
import profileService from "@/services/profileService";
import activityService from "@/services/activityService";
import DistanceTextArea from "@/components/DistanceTextArea";
import DurationTextArea from "@/components/DurationTextArea";

import Header from './_Header.vue';
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";

export default {
  name: "ActivityEdit",
  components: {
    DistanceTextArea,
    DurationTextArea,
    Header,
    DateAndTimeWithTimeZonePicker,
  },
  props: {
  },
  data() {
    return {
      activityService: activityService,
      activity: null,
      profile: null,
      error: null,
      timeZoneOlson: null,
      valid: true,
      siteData: siteData,
      validationError: false,
      infoMessage: null,
      numberRules: [
        () => activityService.validateDistance(this.activity),//(this.activity.dist > 0 || this.activity.type === 'OTHER' || this.activity.type === 'YOGA' || this.activity.type === 'INDOOR_CARDIO' || this.activity.type === 'INDOOR_CYCLING') || "Please enter a positive number",
      ],
      breadcrumbItems: [
        { text: this.$t('profile.title'), href: '/profile' },
        { text: this.$t('profile.activities.title'), href: '/profile/activities' },
        { text: this.$t('profile.activities.edit.title'), disabled: true },
      ],
    };
  },
  async mounted() {
    await this.loadData();

    EventBus.$on('login-state-change', async user => {
      await this.loadData();
    });

    this.timeZoneOlson = DateTime.now().zoneName;

  },
  methods: {
    async loadData() {
      if (this.user) {
        this.profile = (await profileService.get()).data;

        const id = this.$route.query.id;
        if (id) {
          var model = (await activityService.get(id)).data;
          console.log('Activity loaded', model);
          if (model) {
            this.activity = {
              id: id,
              start: model.start,
              type: model.type,
              dist: model.dist,
              elevation_gain: model.elevation_gain,
              elevation_loss: model.elevation_loss,
              kcal: model.kcal,
              custom: model.custom,
              time_s: model.time_s,
            }
          }
        }
        if (!this.activity) {
        }

      }
    },

    activityTypeChanged() {
      console.log('this.activity.type',this.activity.type);
      if (this.activity.type == 'STAIR_CLIMBING') {
        this.activity.dist = 1;
      }
      if (this.$refs.form.value === false) {
        // validation depends on activity type, so revalidate pls
        // only do when currently invalid to prevent that switching activity type shows 
        this.$refs.form.validate(); 
      }
    },

    async submit(ignoreValidation) {
      if (this.$refs.form.validate()) {
        this.error = null;
        this.infoMessage = null;
        this.validationError = false;
        try {
          //var validation = (await activityService.validate(this.activity)).data;
          console.log('Storing manual entry', this.activity);
          var data = (await activityService.edit(this.activity.id, this.activity)).data;
          if (data.status == "OK"){
            this.$router.push({ name: 'activity', params: { id: data.id } });
          }
          else if (data.status == "INFO") {
            this.infoMessage = data.msg;
            this.validationError = true;
          }
          else {
            this.error = data.msg;
            this.validationError = true;
          }
        }
        catch (ex) {
          this.error = ex.response.data.msg;
        }
      }
    },

  },
  computed: {
    showDistance() {
      return this.activity;
    },
    showSteps() {
      return this.activity && ['DAILY_SUMMARY'].some(x => x === this.activity.type);
    },
    showCustomField() {
      return this.activity && (['VOLUNTEERING', 'OTHER'].some(x => x === this.activity.type))
    },
    unitType() {
      return this.profile == null || (['ROWING', 'SWIMMING'].some(x => x === this.activity.type))  ? 'METRIC' : this.profile.unit;
    },
    unitTypeDisplay() {
      return this.unitType == 'METRIC' ? 'km' : 'mi';
    },
    ...mapGetters({
      user: "user"
    }),

  },

};
</script>
<style lang="scss">
</style>

